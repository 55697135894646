<template>
  <div
    :class="$style.root"
    ref="rootTarget"
    :data-mounted="mounted ? '' : null"
  >
    <div :class="$style.content">
      <layout-modal-content-component
        :close-path="closePath"
        id="modal-content"
        ref="contentTarget"
      >
        <template #header>
          <slot name="header" />
        </template>

        <slot />

        <template #footer>
          <slot name="footer" />
        </template>
      </layout-modal-content-component>
    </div>
  </div>
</template>

<script setup lang="ts">
const router = useRouter()
const route = useRoute()

const props = defineProps({
  closePath: {
    type: [Object, String],
    required: false
  }
})

const rootTarget = ref(null)
const contentTarget = ref(null)
const mounted = ref(false)

const closeSelfIfLightboxIsInactive = () => {
  const query = route?.query['lightbox-image-id'] || route?.query['lightbox-video-id']
  const hasLightBox = query?.length > 0
  if (!hasLightBox) {
    router.push(props.closePath)
  }
}

onClickOutside(contentTarget, (e) => {
  closeSelfIfLightboxIsInactive()
}, { ignore: ['#cookie-consent', '#modal-content']})

onKeyStroke('Escape', (e) => {
  closeSelfIfLightboxIsInactive()
})

onMounted(() => {
  setTimeout(() => {
    mounted.value = true
  }, 250)
})

useHead({
  htmlAttrs: {
    'data-overflow-hidden': ''
  }
})

onBeforeUnmount(() => {
  document.documentElement.removeAttribute('data-overflow-hidden')
  mounted.value = false
})

onBeforeRouteUpdate(async (to, from, next) => {
  if (to?.name === from?.name && to?.path !== from?.path) {
    mounted.value = false
    next()
  } else {
    next()
  }
})
</script>

<style module>
.root {
  position: fixed;
  z-index: var(--z-index--modal);

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  transition: backdrop-filter 0.15s;
}

.root[data-mounted] {
  backdrop-filter: var(--backdrop-filter--dark);
}

.content {
  width: 100%;

  max-height: calc(100dvh - var(--unit--vertical) * 2);

  overflow: scroll;

  display: flex;
  flex-direction: column;
  align-items: center;

  transition: opacity 0.15s, transform 0.15s;
}

.root:not([data-mounted]) .content {
  opacity: 0;
  transform: translateY(40px);
  pointer-events: none;
}
</style>
