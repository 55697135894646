export const getClosePath = (name: string, params: object, query: object = {}, locale) => {
  const localePath = useLocalePath()
  const nuxtApp = useNuxtApp()
  const { $i18n } = nuxtApp
  const { locale: currentLocale } = $i18n

  if (!name) { return }

  const paramsObject = params ? { params } : {}
  const route = useRoute()

  const composedQuery = { ...query, ...getStatusQuery() }

  return localePath({
    name,
    ...paramsObject,
    query: composedQuery,
  }, locale || currentLocale.value)
}
