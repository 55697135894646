<template>
  <div
    :class="$style.root"
  >
    <header :class="$style.header">
      <slot name="header" />
    </header>

    <nuxt-link :to="closePath" :class="$style.link" >
      <svgo-cross :class="$style.svg" />
    </nuxt-link>

    <div :class="$style.content">
      <slot />
    </div>

    <layout-modal-content-footer-container-component
      :class="$style.footer"
      v-if="slots.footer && slots.footer()?.length"
    >
      <slot name="footer" />
    </layout-modal-content-footer-container-component>
  </div>
</template>

<script setup lang="ts">
const slots = useSlots()

const props = defineProps({
  closePath: {
    type: [Object, String],
    required: false
  }
})
</script>

<style module lang="scss">
.root {
  composes: reset-focus from global;

  width: calc(var(--width--container) + (var(--unit--horizontal) * 2));
  max-width: calc(100vw - var(--unit--horizontal) * 2);

  background-color: var(--color--background);
  color: var(--color--primary);

  position: relative;

  padding: calc(var(--unit--vertical) * 2) calc(var(--unit--horizontal) * 2);

  grid-template-columns: 1fr var(--size--button);
  display: grid;
  grid-column-gap: var(--unit--horizontal);
  grid-template-areas: 'header link-to-close'
                       'content content'
                       'footer footer';
}

.header {
  margin-bottom: var(--font-default--line-height);
  grid-area: header;
}

.content {
  grid-area: content;
}

.link {
  composes: button-round from global;
  margin: 0;
  grid-area: link-to-close;

  position: sticky;
  top: calc(var(--unit--vertical) * 2);
  z-index: 10;
}

.svg {
  composes: button-svg from global;
}

.footer {
  grid-area: footer;
}
</style>
